<template>
  <div class="contaienr">
    <el-image class="bg" :src="require('@as/event/icon_5.png')"></el-image>
    <div class="main">
      <div class="head">
        <el-image
          class="head_img"
          :src="require('@as/event/icon_5.png')"
        ></el-image>
        <div class="head_content">
          <div class="content_time">
            <div>AUG</div>
            <div class="num">05</div>
          </div>
          <div class="title">掌握国际并购规则，为中英资本跨境投资护航</div>
          <div class="user">
            <el-image
              class="user_img"
              :src="require('@as/event/icon_5.png')"
            ></el-image>
            <div class="user_name">黎莉莉</div>
            <el-image
              class="user_tag"
              :src="require('@as/event/detail/icon_1.png')"
            ></el-image>
          </div>
          <div class="price_tag">免费</div>
        </div>
      </div>
      <div class="data_row">
        <div class="item">
          <el-image
            class="item_icon"
            :src="require('@as/event/detail/icon_3.png')"
          ></el-image>
          <div>浏览 30000</div>
        </div>
        <div class="item">
          <el-image
            class="item_icon"
            :src="require('@as/event/detail/icon_4.png')"
          ></el-image>
          <div>分享</div>
        </div>
        <div class="item">
          <el-image
            class="item_icon"
            :src="require('@as/event/detail/icon_5.png')"
          ></el-image>
          <div>收藏</div>
        </div>
        <div class="join_btn" @click="dialogVisible = true">
          <el-image
            class="join_icon"
            :class="{ gray: active == false }"
            :src="require('@as/event/detail/icon_2.png')"
          ></el-image>
          <div>参加活动</div>
          <template v-if="active">
            <el-image
              class="join_icon"
              :src="require('@as/event/detail/icon_13.png')"
            ></el-image>
            <div>活动结束</div>
          </template>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div class="section">
            <div class="title">活动背景</div>
            <div class="text">
              由于中美贸易战和汇率变动等因素，英国资产对中国投资者的吸引力日益增加，成为中资投资者在欧投资收购的首选。而作为全球经济增长点，中国在金融、保险、航运等市场方面的进一步开放，也为英国资本在中国的投资带来更多机遇。
              但由于文化历史法律背景的差异，中英跨境收购存在诸多障碍，收购目标不明、忽略合规及地缘政治壁垒风险、缺少专业团队扶持等，导致双方缺少共同的商业语言，有些跨境收购投资项目甚至产生负面影响。
              针对上述问题，中伦律师事务所及致同会计师事务所分别作为中英跨境收购中财务风险及法律风险的解决方案专家，总结多年来跨境收购经验，为投资者提出可行的解决方案。
            </div>
          </div>
          <div class="section">
            <div class="title">分享内容</div>
            <div class="text">
              “掌握国际并购规则，为中英资本跨境投资护航”在线讲座分别邀请致同会计师事务所及中伦律师事务所英国及中国大陆专业从事跨境收购的合伙人及专家，以主讲嘉宾单独讲解和嘉宾圆桌讨论的方式进行评析，并吸引各位会议参加者参与讨论和提问，为跨境收购中实际遇到的各项风险和问题提供方案。
            </div>
            <div class="row">
              1. 主题演讲：中国资本海外收购所面临的普遍问题和对策
            </div>
          </div>
          <div class="section">
            <div class="title">活动日程</div>
            <el-timeline>
              <el-timeline-item
                :timestamp="2018 + '/' + 4 + '/' + item"
                :color="'#1C9AB1'"
                placement="top"
                v-for="item in 4"
                v-bind:key="item"
              >
                <div class="info">
                  <div class="info_title">主持人开场</div>
                  <ul class="info_subtitle">
                    <li>掌握国际并购规则，为中英资本跨境投资护航</li>
                  </ul>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
          <div class="section">
            <div class="title">活动嘉宾</div>
            <div class="user_card">
              <el-image
                class="user_img"
                :src="require('@as/event/icon_5.png')"
              ></el-image>
              <div class="user_name">薛海滨</div>
              <div class="user_name">中伦律师事务所合伙人</div>
              <div class="user_detail">
                薛海滨律师曾担任伦敦市政府中国事务和法律专家顾问，他专门从事跨境并购、公开上市、国际投资及公司融资方面的法律服务，近年来带领中伦为诸多的跨境并购业务及项目融资提供法律服务。
              </div>
            </div>
          </div>
          <div class="btn_group">
            <div class="btn active">
              <el-image
                class="btn_icon"
                :src="require('@as/event/detail/icon_11.png')"
              ></el-image>
              <div>分享到微信</div>
            </div>
            <div class="btn">
              <el-image
                class="btn_icon"
                :src="require('@as/event/detail/icon_5.png')"
              ></el-image>
              <div>收藏125</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right_info">
            <div class="info_item">
              <el-image
                class="info_item_icon"
                :src="require('@as/event/detail/icon_6.png')"
              ></el-image>
              <div>2020-08-08 20:00-21:00</div>
            </div>
            <div class="info_item">
              <el-image
                class="info_item_icon"
                :src="require('@as/event/detail/icon_7.png')"
              ></el-image>
              <div>北京市宁穿路金融硅谷产业园区12号楼1215科技空间</div>
            </div>
          </div>
          <div class="right_time">
            <div class="right_time_title">
              <el-image
                class="right_time_icon"
                :src="require('@as/event/detail/icon_8.png')"
              ></el-image>
              <div>倒计时</div>
            </div>
            <div class="right_time_content">
              <div class="time_item" id="item1"></div>
              <div class="time_item" id="item2"></div>
              <div class="time_item" id="item3"></div>
              <div class="time_item" id="item4"></div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/event/detail/icon_9.png')"
                ></el-image>
                <div>主办方/协办方</div>
              </div>
              <div class="title_more"></div>
            </div>
            <div class="section_content">
              <div class="item" v-for="item in 2" v-bind:key="item">
                <el-image
                  class="img"
                  :src="require('@as/event/detail/icon_6.png')"
                ></el-image>
                <div class="name">
                  <div>中伦律师事务所</div>
                  <img
                    class="icon"
                    :src="require('@as/reading/personFlag1.png')"
                    alt=""
                  />
                </div>
                <div class="btn" :class="{ active: item == 1 }">
                  <span>+</span>关注
                </div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/event/detail/icon_10.png')"
                ></el-image>
                <div>演讲嘉宾</div>
              </div>
              <div class="title_more"></div>
            </div>
            <div class="section_content">
              <div class="item" v-for="item in 2" v-bind:key="item">
                <el-image
                  class="img"
                  :src="require('@as/event/detail/icon_6.png')"
                ></el-image>
                <div class="name">
                  <div>中伦律师事务所</div>
                  <img
                    class="icon"
                    :src="require('@as/reading/personFlag1.png')"
                    alt=""
                  />
                </div>
                <div class="btn" :class="{ active: item == 1 }">
                  <span>+</span>关注
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="divider">
          <div class="label">相关标签</div>
          <div class="item active">全部</div>
          <div class="item" v-for="item in 4" v-bind:key="item">
            标签{{ item }}
          </div>
          <div class="btn">
            <el-image
              class="btn_icon"
              :src="require('@as/event/detail/icon_12.png')"
            ></el-image>
            <div>举报</div>
          </div>
        </div>
        <div class="comment">
          <div class="top">
            <div class="left">
              <div class="num">8</div>
              <div>条评论</div>
            </div>
            <div class="right">
              <el-image
                class="icon"
                :src="require('@as/serve/knowledge/icon_10.png')"
              ></el-image>
              <div>我来说两句</div>
            </div>
          </div>
          <el-input
            type="textarea"
            placeholder="说点什么吧……"
            v-model="textarea"
          >
          </el-input>
          <div class="comment_btn">评论</div>
          <div class="list">
            <div class="list_section" v-for="item in 3" v-bind:key="item">
              <el-image
                class="list_icon"
                :src="require('@as/serve/knowledge/icon_5.png')"
              ></el-image>
              <div class="info">
                <div class="title">
                  <div class="name">大人物</div>
                  <div class="time">1天前</div>
                </div>
                <div class="detail">
                  <div class="text">感觉每年的设计趋势都有那几个大条～</div>
                  <div class="reply_btn">
                    <el-image
                      class="icon"
                      :src="require('@as/serve/knowledge/icon_9.png')"
                    ></el-image>
                    <span>回复</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pull_more">加载更多</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="head">
        <div class="title">相关活动</div>
        <div class="title_more">MORE</div>
      </div>
      <div class="list">
        <div class="item" v-for="item in 4" v-bind:key="item" @click="toJoin()">
          <el-image
            class="item_img"
            :src="require('@as/event/banner.png')"
          ></el-image>
          <div class="item_title">
            掌握国际并购规则，为中英资本跨境 投资护航活动
          </div>
          <div class="item_row">
            <el-image
              class="item_label_1"
              :src="require('@as/event/row_icon_1.png')"
            ></el-image>
            <div class="item_value">北京</div>
          </div>
          <div class="item_row">
            <el-image
              class="item_label"
              :src="require('@as/event/row_icon_2.png')"
            ></el-image>
            <div class="item_value">2020.08.12</div>
            <div
              class="item_btn"
              :class="{ active: item == 1, finish: item == 2 }"
            >
              报名中
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="result == 1 ? '参会者信息' : ''"
      :visible.sync="dialogVisible"
      width="930px"
      :before-close="handleClose"
    >
      <div class="pop_content" v-if="result == 1">
        <div class="left">
          <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
            <el-form-item prop="name">
              <el-input v-model="ruleForm.name" placeholder="姓名*"></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input v-model="ruleForm.email" placeholder="邮箱*"></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input
                v-model="ruleForm.phone"
                placeholder="手机号*"
              ></el-input>
            </el-form-item>
            <el-form-item prop="company">
              <el-input
                v-model="ruleForm.company"
                placeholder="公司/单位*"
              ></el-input>
            </el-form-item>
            <el-form-item prop="job">
              <el-input v-model="ruleForm.job" placeholder="职位*"></el-input>
            </el-form-item>
            <el-form-item prop="focus">
              <div class="select_container">
                <el-select v-model="value" placeholder="关注领域">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-image
                  class="select_icon"
                  :src="require('@as/event/detail/icon_14.png')"
                ></el-image>
              </div>
            </el-form-item>
          </el-form>
          <div class="tips">
            请确保提交的信息是真实、准确、完整有效的，并同意慧律网按照此提交的信息检验身份；
            报名结果以最终报名结果短信邀约为准。
          </div>
          <el-button @click="result += 1">提交</el-button>
        </div>
        <div class="right">
          <el-image
            class="pop_img"
            :src="require('@as/event/detail/icon_6.png')"
          ></el-image>
          <div class="pop_right_title">
            掌握国际并购规则，为中英资本跨境投 资护航活动
          </div>
          <div class="pop_right_info">
            <div class="info_item">
              <el-image
                class="info_item_icon"
                :src="require('@as/event/detail/icon_6.png')"
              ></el-image>
              <div>2020-08-08 20:00-21:00</div>
            </div>
            <div class="info_item">
              <el-image
                class="info_item_icon"
                :src="require('@as/event/detail/icon_7.png')"
              ></el-image>
              <div>北京市宁穿路金融硅谷产业园区12号楼1215科技空间</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pop_result" v-else>
        <el-image
          class="result_icon"
          :src="require('@as/event/detail/result_icon.png')"
        ></el-image>
        <div class="result_title">提交成功</div>
        <div class="result_subtitle">
          您报名的活动需要主办方审核，请您耐心等待！
        </div>
        <div class="result_tips">
          主办方审核会有延时性，可能需要您耐心等待几天，您也可以通过活动网站留言咨询或者联系主办方。
        </div>
        <div class="result_btn">查看更多活动</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { RingProgress } from '@antv/g2plot'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        name: '',
        phone: '',
        email: '',
        company: '',
        job: '',
        focus: '',
      },
      result: 1,
      options: [
        {
          value: '选项1',
          label: '黄金糕',
        },
        {
          value: '选项2',
          label: '双皮奶',
        },
        {
          value: '选项3',
          label: '蚵仔煎',
        },
        {
          value: '选项4',
          label: '龙须面',
        },
        {
          value: '选项5',
          label: '北京烤鸭',
        },
      ],
    }
  },
  mounted() {
    const ring1 = new RingProgress('item1', {
      width: 61,
      height: 61,
      autoFit: false,
      percent: 0.7,
      color: ['#1C9AB1', '#dedede'],
      innerRadius: 0.9,
      radius: 1,
      statistic: {
        content: {
          style: { color: '#363636', fontSize: '12px', lineHeight: '14px' },
          customHtml: (_title, value) => {
            let htmlStr = `<div><span style="font-size:16px;color:#1C9AB1">7</span><span style="color:#BBBCBC">天</span><div style="color:#BBBCBC">Days</div></div>`
            return htmlStr
          },
        },
      },
    })
    const ring2 = new RingProgress('item2', {
      width: 61,
      height: 61,
      autoFit: false,
      percent: 0.7,
      color: ['#1C9AB1', '#dedede'],
      innerRadius: 0.9,
      radius: 1,
      statistic: {
        content: {
          style: { color: '#363636', fontSize: '12px', lineHeight: '14px' },
          customHtml: (_title, value) => {
            let htmlStr = `<div><span style="font-size:16px;color:#1C9AB1">11</span><span style="color:#BBBCBC">时</span><div style="color:#BBBCBC">Hours</div></div>`
            return htmlStr
          },
        },
      },
    })
    const ring3 = new RingProgress('item3', {
      width: 61,
      height: 61,
      autoFit: false,
      percent: 0.7,
      color: ['#1C9AB1', '#dedede'],
      innerRadius: 0.9,
      radius: 1,
      statistic: {
        content: {
          style: { color: '#363636', fontSize: '12px', lineHeight: '14px' },
          customHtml: (_title, value) => {
            let htmlStr = `<div><span style="font-size:16px;color:#1C9AB1">27</span><span style="color:#BBBCBC">分</span><div style="color:#BBBCBC">Minutes</div></div>`
            return htmlStr
          },
        },
      },
    })
    const ring4 = new RingProgress('item4', {
      width: 61,
      height: 61,
      autoFit: false,
      percent: 0.7,
      color: ['#1C9AB1', '#dedede'],
      innerRadius: 0.9,
      radius: 1,
      statistic: {
        content: {
          style: { color: '#363636', fontSize: '12px', lineHeight: '14px' },
          customHtml: (_title, value) => {
            let htmlStr = `<div><span style="font-size:16px;color:#1C9AB1">47</span><span style="color:#BBBCBC">分</span><div style="color:#BBBCBC">Seconds</div></div>`
            return htmlStr
          },
        },
      },
    })

    ring1.render()
    ring2.render()
    ring3.render()
    ring4.render()
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    toJoin() {
      this.$router.push('eventJoin')
    },
  },
}
</script>
<style lang="less" scoped>
.contaienr {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  .bg {
    width: 100%;
    height: 667px;
    z-index: 0;
  }
  .main {
    z-index: 1;
    margin: -667px auto 0;
    width: 1200px;
    background: #fff;
    box-shadow: 2px 0 4px 0 rgba(000, 000, 000, 0.1);
    .head {
      display: flex;
      .head_img {
        width: 870px;
        height: 398px;
      }
      .head_content {
        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #ebecec;
        .content_time {
          width: 29px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c9bb2;
          text-align: center;
          .num {
            margin-top: 4px;
            font-size: 17px;
            color: #1c9ab1;
          }
        }
        .title {
          margin-top: 18px;
          padding-top: 18px;
          border-top: 2px solid #dedede;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          line-height: 28px;
        }
        .user {
          margin-top: 27px;
          display: flex;
          align-items: center;
          .user_img {
            width: 20px;
            height: 20px;
            border-radius: 50px;
          }
          .user_name {
            margin-left: 8px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 100;
            color: #595757;
          }
          .user_tag {
            margin-left: 8px;
            width: 47px;
            height: 16px;
          }
        }
        .price_tag {
          padding: 3px 8px;
          margin-top: auto;
          display: inline;
          border: 1px solid #1c9bb2;
          border-radius: 3px;
          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          letter-spacing: 3px;
          color: #1c9ab1;
        }
      }
    }
    .data_row {
      padding: 7px 24px;
      display: flex;
      align-items: center;
      background: #fff;
      border-bottom: 1px solid #dedede;
      .item {
        display: flex;
        align-items: center;
        margin-right: 27px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #bbbcbc;
        cursor: pointer;
        .item_icon {
          margin-right: 4px;
        }
      }
      .join_btn {
        margin-left: auto;
        padding: 12px 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
        background: #1c9ab1;
        border-radius: 4px;
        cursor: pointer;
        .join_icon {
          margin-right: 9px;
          width: 20px;
          height: 20px;
        }
        &.gray {
          color: #bbbcbc;
          background: #ebecec;
        }
      }
    }
    .content {
      display: flex;
      .left {
        padding: 43px 109px;
        .section {
          &:not(:first-child) {
            margin-top: 57px;
          }
          .title {
            padding-left: 14px;
            border-left: 7px solid #1c9ab1;
            font-size: 17px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
          }
          .text {
            margin-top: 20px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            line-height: 28px;
          }
          .row {
            margin-top: 16px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
          }
          .user_card {
            margin-top: 40px;
            text-align: center;
            .user_img {
              width: 145px;
              height: 145px;
              border: 2px solid #1c9bb2;
              border-radius: 50%;
            }
            .user_name {
              margin-top: 20px;
              font-size: 15px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
            }
            .user_detail {
              margin-top: 30px;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
              line-height: 28px;
              text-align: left;
            }
          }
          & /deep/ .el-timeline-item__timestamp.is-top {
            padding-top: 0;
            margin-right: 20px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #808080;
          }
          & /deep/ .el-timeline {
            margin-top: 50px;
          }
          & /deep/ .el-timeline-item__wrapper {
            display: flex;
            padding-left: 22px;
          }
          & /deep/ .el-timeline-item__tail {
            top: 4px;
            border-left-width: 3px;
          }
          & /deep/ .el-timeline-item__node--normal {
            top: 4px;
            width: 8px;
            height: 8px;
            left: 2px;
          }
          .info {
            .info_title {
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
            }
            .info_subtitle {
              margin-top: 10px;
              padding-left: 16px;
              display: flex;
              li {
                margin-right: 30px;
                list-style: disc;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #595757;
              }
            }
          }
        }
        .btn_group {
          margin-top: 58px;
          display: flex;
          justify-content: center;
          .btn {
            margin-right: 32px;
            padding: 14px 52px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            border-radius: 52px;
            background: #ebecec;
            &.active {
              color: #fff;
              background: #1c9bb2;
            }
            .btn_icon {
              margin-right: 5px;
            }
          }
        }
      }
      .right {
        padding-right: 24px;
        margin-top: 47px;
        flex-shrink: 0;
        width: 300px;
        .right_info {
          .info_item {
            display: flex;
            line-height: 27px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #808080;
            &:first-child {
              margin-bottom: 10px;
              align-items: center;
              .info_item_icon {
                margin-top: 0;
                width: 19px;
                height: 20px;
              }
            }
            .info_item_icon {
              flex-shrink: 0;
              margin-top: 6px;
              margin-right: 11px;
              width: 18px;
              height: 22px;
            }
          }
        }
        .right_time {
          margin-top: 35px;
          padding: 8px 0;
          border: 1px solid #dedede;
          .right_time_title {
            padding: 0 13px 10px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            border-bottom: 1px solid #dedede;
            .right_time_icon {
              margin-right: 6px;
            }
          }
          .right_time_content {
            padding: 16px 0 8px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            .time_item {
              width: 61px;
              height: 61px;
            }
          }
        }
        .section {
          margin: 30px 0;
          padding-bottom: 20px;
          background: #fff;

          .head {
            display: flex;
            justify-content: space-between;
            .title {
              padding-bottom: 6px;
              display: flex;
              align-items: center;
              font-size: 18px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
              border-bottom: 2px solid #4a97ad;
              .title_icon {
                margin-right: 10px;
              }
            }
            .title_more {
              padding-bottom: 6px;
              flex: 1;
              font-size: 10px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #a6a7a7;
              text-align: right;
              border-bottom: 1px solid #dedede;
            }
          }
          .section_content {
            .item {
              margin-top: 25px;
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
              .img {
                margin-right: 16px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }
              .btn {
                padding: 0 11px;
                margin-left: auto;
                height: 35px;
                line-height: 35px;
                font-size: 15px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #babbbb;
                border: 1px solid #babbbb;
                text-align: center;
                box-sizing: border-box;
                span {
                  font-size: 20px;
                  line-height: 20px;
                }
                &.active {
                  color: #1c9ab1;
                  border-color: #cbe8ed;
                }
              }
              .name {
                display: flex;
                .icon {
                  margin-left: 5px;
                  width: 10px;
                  height: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
  & /deep/ .el-dialog__title {
    font-size: 18px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #1c9ab1;
  }
  & /deep/ .el-dialog__headerbtn {
    font-size: 24px;
  }
  & /deep/ .el-dialog__headerbtn:hover .el-dialog__close {
    color: #909399;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
  }
  .pop_content {
    display: flex;
    border-top: 1px solid #eee;
    .left {
      padding: 35px 40px 62px;
      border-right: 1px solid #eee;
      & /deep/ .el-input__inner {
        border-color: #dedede;
        &:focus {
          border-color: #1c9ab1;
        }
      }
      .tips {
        margin-top: 30px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        line-height: 20px;
      }
      & /deep/ .el-button {
        margin-top: 18px;
        padding: 17px 106px;
        color: #fff;
        border-radius: 4px;
        background: #1c9ab1;
      }
      & /deep/ .el-select {
        width: 100%;
      }
      & /deep/ .el-input__suffix-inner {
        display: none;
      }
      .select_container {
        position: relative;
        .select_icon {
          position: absolute;
          top: 40%;
          right: 15px;
        }
      }
    }
    .right {
      padding: 20px;
      .pop_img {
        width: 328px;
        height: 158px;
      }
      .pop_right_title {
        margin-top: 20px;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
      }
      .pop_right_info {
        margin-top: 42px;
      }
      .info_item {
        margin-top: 10px;
        display: flex;
        line-height: 27px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #808080;
        &:first-child {
          align-items: center;
          .info_item_icon {
            margin-top: 0;
            width: 19px;
            height: 20px;
          }
        }
        .info_item_icon {
          flex-shrink: 0;
          margin-top: 6px;
          margin-right: 11px;
          width: 18px;
          height: 22px;
        }
      }
    }
  }
  .pop_result {
    padding: 32px 32px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .result_icon {
      margin-left: 25px;
      width: 140px;
      height: 81px;
    }
    .result_title {
      margin-top: 30px;
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
    }
    .result_subtitle {
      margin-top: 30px;
      font-size: 14px;
      color: #808080;
    }
    .result_tips {
      margin-top: 8px;
      font-size: 12px;
      color: #a7a8a8;
    }
    .result_btn {
      margin-top: 68px;
      padding: 12px 114px;
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #eeffff;
      background: #1c9ab1;
    }
  }
  .bottom {
    padding: 0 109px;
    .divider {
      margin-top: 50px;
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      border-bottom: 3px solid #e8e8e8;
      .label {
        margin-right: 17px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #babbbb;
      }
      .item {
        margin-right: 16px;
        padding: 10px 20px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        border: 1px solid #cccccc;
        border-radius: 2px;
        &.active {
          color: #089acb;
          border-color: #089acb;
        }
      }
      .btn {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #bbbcbc;
        .btn_icon {
          margin-right: 2px;
          width: 18px;
          height: 15px;
        }
      }
    }
    .comment {
      margin-top: 52px;
      padding-bottom: 27px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: #000000;
          .num {
            margin-right: 6px;
            font-size: 18px;
            color: #4b99af;
          }
        }
        .right {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #999999;
          .icon {
            margin-right: 6px;
            width: 18px;
            height: 18px;
          }
        }
      }
      & /deep/ .el-textarea__inner {
        padding: 0 10px;
        margin: 24px 0 18px;
        font-size: 14px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 50px;
        background: #f7f7f7;
        border: 1px solid #ededed;
        border-radius: 4px;
        box-sizing: border-box;
      }
      .comment_btn {
        margin-left: auto;
        width: 80px;
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-family: SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        background: #089acb;
        border-radius: 4px;
        cursor: pointer;
      }
      .list {
        margin-top: 25px;
        border-top: 1px solid #eee;
        .list_section {
          padding: 20px 0;
          display: flex;
          border-bottom: 1px solid #eee;
          .list_icon {
            margin-right: 20px;
            width: 40px;
            height: 40px;
          }
          .info {
            flex: 1;
            .title {
              display: flex;
              align-items: center;
              .name {
                font-size: 14px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #333333;
              }
              .time {
                margin-left: 19px;
                font-size: 12px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #999999;
              }
            }
            .detail {
              display: flex;
              justify-content: space-between;
              margin-top: 10px;
              width: 100%;
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #666666;
              .text {
                flex: 1;
              }
              .reply_btn {
                margin-top: auto;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-family: SourceHanSansCN;
                font-weight: 400;
                color: #9c9c9c;
                cursor: pointer;
                .icon {
                  margin-right: 6px;
                  width: 14px;
                  height: 13px;
                }
              }
            }
          }
        }
      }
      .pull_more {
        margin-top: 27px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        text-align: center;
        background: rgba(28, 155, 178, 0.08);
        cursor: pointer;
      }
    }
  }
  .footer {
    margin: 30px auto 56px;
    width: 1200px;
    .head {
      display: flex;
      justify-content: space-between;
      .title {
        padding: 0 16px 6px 22px;
        font-size: 18px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        border-bottom: 2px solid #4a97ad;
      }
      .title_more {
        padding-bottom: 6px;
        flex: 1;
        font-size: 10px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a6a7a7;
        text-align: right;
        border-bottom: 1px solid #dedede;
        cursor: pointer;
      }
    }
    .list {
      margin-top: 35px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        margin-bottom: 20px;
        padding-bottom: 30px;
        width: 271px;
        box-sizing: border-box;
        background: #fff;
        cursor: pointer;
        &:hover {
          box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.1);
        }
        .item_img {
          width: 269px;
          height: 130px;
        }
        .item_title {
          padding: 20px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c9ab1;
          line-height: 26px;
        }

        .item_row {
          margin-top: 16px;
          padding: 0 20px;
          display: flex;
          align-items: flex-end;
          &:first-child {
            margin-top: 44px;
          }
          .item_label_1 {
            margin-right: 3px;
            width: 14px;
            height: 20px;
          }
          .item_label {
            width: 18px;
            height: 18px;
          }
          .item_value {
            margin-left: 14px;
            margin-bottom: 4px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #808080;
          }
          .item_btn {
            margin-left: auto;
            padding: 7px 17px;
            border-radius: 4px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
            background: #1c9ab1;
            &.active {
              background: #f08d1d;
            }
            &.finish {
              background: #939494;
            }
          }
        }
      }
    }
  }
}
</style>